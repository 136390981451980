import React, { Fragment } from 'react';

import { planAppStateText, planAppStates } from 'sow/constants/planApp';
import Spinner from 'sow/components/atoms/Spinner';
import Block from 'sow/components/atoms/Block';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormGroup from 'sow/components/organisms/FormGroup';
import Input from 'sow/components/atoms/Input';
import Paragraph from 'sow/components/atoms/Paragraph';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Clearfix from 'sow/components/atoms/Clearfix';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import ToFromDateFormGroup from 'sow/components/molecules/ToFromDateFormGroup';
import RadioButton from 'sow/components/atoms/RadioButton';

const AcaOrgSearch = ({
  state,
  handleReset,
  handleSearch,
  handleSubmit,
  handleFilterClick,
  handleOptionChange,
  handleInputChange,
  handleSelectChange,
  handleDateChange,
  handleMultiSelectChange,
  exportList,
  inspectorList,
  specialistList,
  acaList,
  isCertSpecialist,
  isAdmin,
  hasResults,
}) => {
  const { formValues, formErrors, selectOptions } = state;

  const ospStatusOptions = [];

  for (const property in planAppStates) {
    if (property !== 'NONCOMPLIANCE')
      ospStatusOptions.push({
        value: planAppStates[property],
        label: planAppStateText[planAppStates[property]],
      });
  }

  ospStatusOptions.push({ value: 'not_started', label: 'Not Started' });

  const inspectorOptions = [];

  inspectorList.forEach(inspector => {
    inspectorOptions.push({ value: inspector.id, label: inspector.name });
  });

  const specialistOptions = [];

  specialistList.forEach(specialist => {
    specialistOptions.push({ value: specialist.id, label: specialist.name });
  });

  const ospTagOptions = [
    { value: 'noncompliance', label: 'Noncompliance' },
    { value: 'underInvestigation', label: 'Under Investigation' },
    { value: 'settlementAgreement', label: 'Settlement Agreement' },
  ];

  const acaOptions = [];

  for (const property in acaList) {
    acaOptions.push({ value: property, label: acaList[property].name });
  }

  const hardCopyRadioOptions = [
    { value: true, label: 'Yes', stateName: 'hardCopy' },
    { value: false, label: 'No', stateName: 'hardCopy' },
  ];

  const newClientsRadioOptions = [
    { value: true, label: 'Yes', stateName: 'newClient' },
    { value: false, label: 'No', stateName: 'newClient' },
  ];

  const activeStatusRadioOptions = [
    { value: true, label: 'Yes', stateName: 'activeStatuses' },
    { value: false, label: 'No', stateName: 'activeStatuses' },
  ];

  const csAssignedRadioOptions = [
    { value: true, label: 'Yes', stateName: 'csAssigned' },
    { value: false, label: 'No', stateName: 'csAssigned' },
  ];

  const statusRadioOptions = [
    { value: true, label: 'Enabled', stateName: 'status' },
    { value: false, label: 'Disabled', stateName: 'status' },
  ];

  if (!selectOptions) {
    return <Spinner />;
  }

  return (
    <Widget>
      <WidgetHeading className="filter-heading">
        Organization Search Filter
        <Block pullRight>
          {state.allFilters ? (
            <Button type="button" className="filter-button" onClick={handleFilterClick}>
              <span className="material-icons-outlined">filter_list</span>
              <Paragraph className="filter-text">Show Less Filters</Paragraph>
              <FontAwesome icon="caret-up" className="filter-caret" />
            </Button>
          ) : (
            <Button type="button" className="filter-button" onClick={handleFilterClick}>
              <span className="material-icons-outlined">filter_list</span>
              <Paragraph className="filter-text">Show All Filters</Paragraph>
              <FontAwesome icon="caret-down" className="filter-caret" />
            </Button>
          )}
          {hasResults && (
            <Button onClick={exportList} primary sm>
              <Glyphicon glyph="export" /> Export
            </Button>
          )}
        </Block>
        <Clearfix />
      </WidgetHeading>
      <WidgetBody className="filter-body">
        <form onSubmit={handleSubmit}>
          <Row className="search-filter-row">
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <InputFormGroup
                id="orgName"
                label="Organization Name"
                className="search-filter"
                inputClassName="input-filter"
                onChange={handleInputChange}
                value={formValues.orgName}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="ospStatus"
                label="OSP Status"
                className="search-filter"
                options={ospStatusOptions}
                clearable={true}
                multi={true}
                onChange={target => handleMultiSelectChange('ospStatus', target)}
                value={formValues.ospStatus}
                placeholder="Select OSP Status"
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <ToFromDateFormGroup
                fieldName="anniversaryDate"
                label="Anniversary Date"
                className="search-filter"
                dateFormat="MM/DD/YYYY"
                onChange={(event, field) => handleDateChange(event, field)}
                startId={'anniversaryDateStart'}
                endId={'anniversaryDateEnd'}
                dateTimeClassName="date-field form-control"
                startDateClassName="date-field form-control start-date"
                startDateValue={formValues.anniversaryDateStart}
                endDateValue={formValues.anniversaryDateEnd}
                fieldErrors={formErrors}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="inspectorSelect"
                label="Inspector Assigned"
                className="search-filter"
                options={inspectorOptions}
                clearable={true}
                onChange={handleSelectChange}
                value={formValues.inspectorSelect}
                placeholder="Select Inspector"
              />
            </Column>
            {(!isCertSpecialist || isAdmin) && (
              <Fragment>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <SelectFormGroup
                    fieldName="initialReviewerSelect"
                    label="Initial Reviewer"
                    className="search-filter"
                    options={specialistOptions}
                    clearable={true}
                    multi={true}
                    onChange={target =>
                      handleMultiSelectChange('initialReviewerSelect', target)
                    }
                    value={formValues.initialReviewerSelect}
                    placeholder="Select Initial Reviewer"
                  />
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <SelectFormGroup
                    fieldName="finalReviewerSelect"
                    label="Final Reviewer"
                    className="search-filter"
                    options={specialistOptions}
                    clearable={true}
                    multi={true}
                    onChange={target =>
                      handleMultiSelectChange('finalReviewerSelect', target)
                    }
                    value={formValues.finalReviewerSelect}
                    placeholder="Select Final Reviewer"
                  />
                </Column>
              </Fragment>
            )}
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <FormGroup
                fieldName="statusAge"
                label="Status Age"
                className="search-filter status-age-form"
                errors={formErrors}
              >
                <Input
                  value={formValues.statusStartTime}
                  onChange={handleInputChange}
                  id="statusStartTime"
                  className="input-filter status-age-input start-date"
                />
                <Input
                  value={formValues.statusEndTime}
                  onChange={handleInputChange}
                  id="statusEndTime"
                  className="input-filter status-age-input"
                />
                <Clearfix />
              </FormGroup>
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="category"
                label="Category"
                className="search-filter"
                clearable={true}
                multi={true}
                onChange={target => handleMultiSelectChange('category', target)}
                value={formValues.category}
                placeholder="Select Category"
                options={selectOptions.organization_type_options}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="ospFlag"
                label="OSP Tags"
                className="search-filter"
                options={ospTagOptions}
                clearable={true}
                multi={true}
                onChange={target => handleMultiSelectChange('ospFlag', target)}
                value={formValues.ospFlag}
                placeholder="Select OSP Tags"
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="certifications"
                label="Certifications"
                className="search-filter"
                options={selectOptions.certification_options}
                clearable={true}
                multi={true}
                onChange={target => handleMultiSelectChange('certifications', target)}
                value={formValues.certifications}
                placeholder="Select Certifications"
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="consultantCompanies"
                label="Consultant Companies"
                className="search-filter"
                options={selectOptions.consultant_options}
                clearable={true}
                multi={true}
                onChange={target =>
                  handleMultiSelectChange('consultantCompanies', target)
                }
                value={formValues.consultantCompanies}
                placeholder="Select Consultant Companies"
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <InputFormGroup
                id="nopID"
                label="NOP ID"
                className="search-filter"
                inputClassName="input-filter"
                onChange={handleInputChange}
                value={formValues.nopID}
              />
            </Column>
            {state.allFilters && (
              <Fragment>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <SelectFormGroup
                    fieldName="acaSelect"
                    label="ACA"
                    className="search-filter"
                    options={acaOptions}
                    clearable={true}
                    onChange={handleSelectChange}
                    value={formValues.acaSelect}
                    placeholder="Select ACA"
                    disabled={true}
                  />
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <ToFromDateFormGroup
                    fieldName="inspectionDate"
                    label="Inspection Date Assigned"
                    className="search-filter"
                    dateFormat="MM/DD/YYYY"
                    onChange={(event, field) => handleDateChange(event, field)}
                    startId={'inspectionDateStart'}
                    endId={'inspectionDateEnd'}
                    dateTimeClassName="date-field form-control"
                    startDateClassName="date-field form-control start-date"
                    startDateValue={formValues.inspectionDateStart}
                    endDateValue={formValues.inspectionDateEnd}
                    fieldErrors={formErrors}
                  />
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <InputFormGroup
                    fieldName="productName"
                    id="productName"
                    label="Product Name"
                    className="search-filter"
                    inputClassName="input-filter"
                    onChange={handleInputChange}
                    value={formValues.productName}
                    fieldErrors={formErrors}
                  />
                </Column>
                {/* Add back when ingredient table is created */}
                {/* <InputFormGroup
                  fieldName="ingredientName"
                  id="ingredientName"
                  label="Ingredient Name"
                  className="search-filter"
                  inputClassName="input-filter"
                  onChange={handleInputChange}
                  value={formValues.ingredientName}
                  fieldErrors={formErrors}
                /> */}
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <InputFormGroup
                    fieldName="locationCount"
                    id="locationCount"
                    label="Location Count"
                    className="search-filter"
                    inputClassName="input-filter"
                    onChange={handleInputChange}
                    value={formValues.locationCount}
                    fieldErrors={formErrors}
                  />
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <InputFormGroup
                    fieldName="locationState"
                    id="locationState"
                    label="Location/State"
                    className="search-filter"
                    inputClassName="input-filter"
                    onChange={handleInputChange}
                    value={formValues.locationState}
                    fieldErrors={formErrors}
                  />
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <FormGroup
                    fieldName="hardCopy"
                    label="Hard Copy"
                    className="search-filter"
                  >
                    <RadioButton
                      options={hardCopyRadioOptions}
                      labelClassName="radio-label"
                      inputClassName="radio-input"
                      onChange={handleOptionChange}
                      currentState={formValues.hardCopy}
                    />
                  </FormGroup>
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <FormGroup
                    fieldName="newClient"
                    label="New Client"
                    className="search-filter"
                  >
                    <RadioButton
                      options={newClientsRadioOptions}
                      labelClassName="radio-label"
                      inputClassName="radio-input"
                      onChange={handleOptionChange}
                      currentState={formValues.newClient}
                    />
                  </FormGroup>
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <FormGroup
                    fieldName="activeStatuses"
                    label="Only Active Statuses"
                    className="search-filter"
                  >
                    <RadioButton
                      options={activeStatusRadioOptions}
                      labelClassName="radio-label"
                      inputClassName="radio-input"
                      onChange={handleOptionChange}
                      currentState={formValues.activeStatuses}
                    />
                  </FormGroup>
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <FormGroup
                    fieldName="csAssigned"
                    label="Is CS Assigned?"
                    className="search-filter"
                  >
                    <RadioButton
                      options={csAssignedRadioOptions}
                      labelClassName="radio-label"
                      inputClassName="radio-input"
                      onChange={handleOptionChange}
                      currentState={formValues.csAssigned}
                    />
                  </FormGroup>
                </Column>
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <FormGroup
                    fieldName="status"
                    label="Account Status"
                    className="search-filter"
                  >
                    <RadioButton
                      options={statusRadioOptions}
                      labelClassName="radio-label"
                      inputClassName="radio-input"
                      onChange={handleOptionChange}
                      currentState={formValues.status}
                    />
                  </FormGroup>
                </Column>
              </Fragment>
            )}
          </Row>
          <Row className="search-filter-row">
            <Column
              className="search-filter-column button-column"
              xs={12}
              sm={12}
              md={4}
              lg={3}
            >
              <Row className="search-filter-row">
                <Column
                  className="search-filter-column button-column"
                  xs={6}
                  sm={6}
                  md={6}
                >
                  <Button
                    type="submit"
                    className="search-button filter-command-button"
                    onClick={handleSearch}
                    primary
                  >
                    Search
                  </Button>
                </Column>
                <Column
                  className="search-filter-column button-column"
                  xs={6}
                  sm={6}
                  md={6}
                >
                  <Button
                    type="button"
                    className="reset-button filter-command-button"
                    onClick={handleReset}
                    primary
                  >
                    Reset
                  </Button>
                </Column>
              </Row>
            </Column>
          </Row>
        </form>
      </WidgetBody>
    </Widget>
  );
};

export default AcaOrgSearch;
